import { Fragment, useState } from "react";
import "./Question_paper_solution.scss";
import useMathjax from "../../../../../../hooks/useMathjax";
import { Link } from "react-router-dom";
import images from "../.././../../../../utilities/images/images";
import ShakaSolutionViewVideoPlayer from "../../../../../../custom_utilities/shakaVideoPlayer/ShakaSolutionViewVideoPlayer";
import useStopwatch from "../../../../../../hooks/useStopwatch";

const Question_paper_solution = (props) => {
  let {
    testQuestionsDetails,
    currentQuestion,
    currentSolutionDetails,
    handlePreviousQuestionBtn,
    handleNextQuestionBtn,
    handleQuestionJump,
    userData,
    handleReview,
  } = props;

  let { userId } = userData;
  const [lang, setLang] = useState(true);
  const [toggleMenu, setToggleMenu] = useState(false);
  const [isList, setisList] = useState(true);

  useMathjax(currentSolutionDetails, lang, isList);

  const renderOptions = (currentSolutionDetails) => {
    if (currentSolutionDetails) {
      let step,
        type = currentSolutionDetails?.answer_type.toLowerCase();

      if (type === "single correct") {
        step = 1;
      } else if (type === "integer correct") {
        step = 2;
      } else {
        step = 3;
      }

      switch (step) {
        case 1:
          return renderSingleSelect(currentSolutionDetails);
        case 2:
          return renderInputField(currentSolutionDetails);
        case 3:
          return renderMultiSelect(currentSolutionDetails);
        default:
          return null;
      }
    } else {
      return null;
    }
  };
  const [videoShow, setVideoShow] = useState(false);

  const classEditor = (s, u) => {
    if (s) {
      return "single_option_wrapper classCorrect";
    } else if (!s && u) {
      return "single_option_wrapper classWrong";
    } else {
      return "single_option_wrapper";
    }
  };

  const renderCorrectWrongIcon = (s, u) => {
    if (s && u) {
    } else if (!s && u) {
    } else {
      return "";
    }
  };

  const { stopwatchText, updateTime } = useStopwatch(
    currentQuestion,
    testQuestionsDetails?.[currentQuestion]?.integer_answer,
    currentSolutionDetails
  );
  const classEditorForInput = (currentQuestionDetails) => {
    const { check_answer } = currentQuestionDetails;

    if (check_answer === 1) {
      return "input_inner_wrapper classCorrect";
    } else if (check_answer === 0) {
      return "input_inner_wrapper classWrong";
    } else {
      return "input_inner_wrapper classUnattempted";
    }
  };

  const renderCorrectWrongInputIcon = (currentQuestionDetails) => {
    const { check_answer } = currentQuestionDetails;

    if (check_answer === 1) {
      return <img src={images.user_activity_59} alt="user_activity" />;
    } else if (check_answer === 0) {
      return <img src={images.user_activity_60} alt="user_activity" />;
    } else {
      return "";
    }
  };

  const renderSingleSelect = (questionData) => {
    return (
      <Fragment>
        <div className="single_select_option_collection_wrapper">
          {questionData?.option.length
            ? questionData.option.map((element, index) => {
                return (
                  <div key={index} className="single_select">
                    <input
                      type="checkbox"
                      id={element.option_id}
                      name={`current_question_${currentQuestion}`}
                      disabled
                    />
                    <label
                      htmlFor={element.option_id}
                      className={classEditor(
                        element.is_correct,
                        element.is_user_correct_ans
                      )}
                    >
                      <div className="options">
                        <div className="option_initial">
                          <p className="text_content_2">{`${renderOptionInitial(
                            index
                          )}.`}</p>
                        </div>
                        <div className="option_final">
                          <p
                            className="text_content_3"
                            dangerouslySetInnerHTML={renderOptionText(
                              lang ? element.option : element.option_hindi // Toggle language for options
                            )}
                          ></p>
                        </div>
                      </div>

                      {renderCorrectWrongIcon(
                        element.is_correct,
                        element.is_user_correct_ans
                      )}
                    </label>
                  </div>
                );
              })
            : null}
        </div>
      </Fragment>
    );
  };

  const renderMultiSelect = (questionData) => {
    return (
      <Fragment>
        <div className="multi_select_option_collection_wrapper">
          {questionData?.option.length
            ? questionData.option.map((element, index) => {
                return (
                  <div key={index} className="single_select">
                    <input
                      type="checkbox"
                      id={element.option_id}
                      name={`current_question_${currentQuestion}`}
                      disabled
                    />
                    <label
                      htmlFor={element.option_id}
                      className={classEditor(
                        element.is_correct,
                        element.is_user_correct_ans
                      )}
                    >
                      <div className="options">
                        <div className="option_initial">
                          <p className="text_content_2">{`${renderOptionInitial(
                            index
                          )}.`}</p>
                        </div>
                        <div className="option_final">
                          <p
                            className="text_content_3"
                            dangerouslySetInnerHTML={renderOptionText(
                              lang ? element.option : element.option_hindi // Toggle language for options
                            )}
                          ></p>
                        </div>
                      </div>

                      {renderCorrectWrongIcon(
                        element.is_correct,
                        element.is_user_correct_ans
                      )}
                    </label>
                  </div>
                );
              })
            : null}
        </div>
      </Fragment>
    );
  };

  const renderInputField = () => {
    return (
      <Fragment>
        <div className="input_wrapper">
          <div
            className={classEditorForInput(
              testQuestionsDetails[currentQuestion]
            )}
          >
            <div className="input_content">
              <div className="input_box">
                <input
                  key={currentQuestion}
                  type="number"
                  value={testQuestionsDetails[currentQuestion]?.integer_answer}
                  disabled
                />
              </div>
              <div className="input_right_wrong_icon">
                {renderCorrectWrongInputIcon(
                  testQuestionsDetails[currentQuestion]
                )}
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  };

  const renderQuestionText = (currentSolutionDetails) => {
    return {
      __html: currentSolutionDetails?.q_text.replace(
        'src="ckfinder',
        'src="http://quizmaster.motion.ac.in/ckfinder'
      ),
    };
  };

  const renderParagraphQuestionText = (currentSolutionDetails) => {
    return {
      __html: currentSolutionDetails?.p_text,
    };
  };

  const renderColumMatchQuestion = (currentSolutionDetails) => {
    return {
      __html: currentSolutionDetails?.m_p_text,
    };
  };

  const handleOnLoad = () => {
    let frame = document.querySelector("#iframe");
    frame.contentWindow.postMessage(
      `{"activate": true,"userId":"${userId}"}`,
      "*"
    );

    // //////jump to particular time///////////////////////
    // frame.contentWindow.postMessage(JSON.stringify({ seekTo: 1 }), "*");

    ////////////////testing///////////////////////////////////
    let eventMethod = window.addEventListener
      ? "addEventListener"
      : "attachEvent";
    let eventer = window[eventMethod];
    let messageEvent = eventMethod === "attachEvent" ? "onmessage" : "message";

    eventer(messageEvent, function (e) {
      if (e.data && e.data.currentTime) {
        let videoTimmingDetails = { currentTime: e.data.currentTime };
        sessionStorage.setItem(
          "videoTimmingDetails",
          JSON.stringify(videoTimmingDetails)
        );
      }
    });
  };

  const handleVideoShow = () => {
    setVideoShow(!videoShow);
    const targetDiv = document.querySelector(".sub_section_wrapper");
    setTimeout(() => {
      targetDiv.scrollTop = targetDiv.scrollHeight;
    }, 1);
  };

  const renderVideo = (currentQuestionDetails) => {
    const videoId = currentQuestionDetails?.video_id_english;

    const selectedVideoId = videoId;

    const urlPattern = /^https:\/\/speed\.mogiio\.com\/embed3\/[0-9a-f]{24}$/;

    const isIframe = urlPattern.test(selectedVideoId);

    const buttonLabel = videoShow
      ? "Close Video Solution"
      : "View Video Solution";

    if (selectedVideoId && selectedVideoId.trim() !== "") {
      return (
        <div className="videoWrapper">
          <button className="view_videoSolution_btn" onClick={handleVideoShow}>
            {buttonLabel}
          </button>

          {videoShow && isIframe ? (
            <iframe
              title="selectvideo"
              id="iframe"
              src={selectedVideoId}
              onLoad={handleOnLoad}
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          ) : null}
          {videoShow && !isIframe ? (
            <ShakaSolutionViewVideoPlayer
              id_video={selectedVideoId}
              className="shakaPlayer"
            />
          ) : null}
        </div>
      );
    } else {
      return null;
    }
  };

  const renderSolution = (currentSolutionDetails) => {
    const { sol_text, sol_text_hindi } = currentSolutionDetails;
    return {
      __html: lang
        ? sol_text?.replaceAll(
            "/ckfinder" || "ckfinder",
            "http://quizmaster.motion.ac.in/ckfinder"
          )
        : sol_text_hindi?.replaceAll(
            "/ckfinder" || "ckfinder",
            "http://quizmaster.motion.ac.in/ckfinder"
          ),
    };
  };

  const renderSingleQuestion = (element) => {
    if (lang) {
      return (
        <p
          dangerouslySetInnerHTML={{
            __html: element.q_text,
          }}
        ></p>
      );
    } else {
      // Render Hindi version of the question if available
      return element?.q_text_hindi ? (
        <p
          dangerouslySetInnerHTML={{
            __html: element.q_text_hindi,
          }}
        ></p>
      ) : (
        <p
          dangerouslySetInnerHTML={{
            __html: element.q_text, // Fallback to English if Hindi isn't available
          }}
        ></p>
      );
    }
  };

  let ConvertStringToHTML = function (str) {
    if (!str) {
      return false;
    }
    let parser = new DOMParser();
    let doc = parser.parseFromString(str, "text/html");

    return doc.body.children.length > 0;
  };
  const renderOptionInitial = (index) => {
    let alphabetArr = [
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "U",
      "V",
      "W",
      "X",
      "Y",
      "Z",
    ];

    return alphabetArr[index];
  };

  const renderOptionText = (optionText) => {
    return { __html: optionText };
  };
  const removeHTMLTags = (str) => {
    const doc = new DOMParser().parseFromString(str, "text/html");
    return doc.body.textContent || "";
  };

  const renderQuestionLabel1 = (question, index) => {
    const { q_text } = question;
    let textToDisplay = q_text.replaceAll(
      'src="ckfinder',
      'src="http://quizmaster.motion.ac.in/ckfinder'
    );

    if (currentQuestion === index) {
      if (textToDisplay && textToDisplay.length > 0) {
        return (
          <label
            className="unanswered_lbl current_lbl"
            onClick={() => {
              updateTime();
              handleQuestionJump(index);
              setVideoShow(false);
            }}
          >
            <div className="questionBox">{`${index + 1}`}</div>
          </label>
        );
      } else {
        return (
          <label
            className="unanswered_lbl current_lbl"
            onClick={() => {
              updateTime();
              handleQuestionJump(index);
              setVideoShow(false);
            }}
          >
            <div className="questionBox">{`${index + 1}`}</div>
          </label>
        );
      }
    } else {
      let labelClass = "";

      if (question.check_answer === 1) {
        labelClass = "correct_answered_lbl";
      } else if (question.check_answer === 0) {
        labelClass = "incorrect_answered_lbl";
      } else {
        labelClass = "";
      }

      return (
        <label
          className={`unanswered_lbl ${labelClass}`}
          onClick={() => {
            updateTime();
            handleQuestionJump(index);
            setVideoShow(false);
          }}
        >
          <div className="questionBox">{`${index + 1}`}</div>
        </label>
      );
    }
  };

  const renderQuestionLabel = (question, index) => {
    const { q_text, q_text_hindi } = question;
    const isTable = q_text.includes("<table");
    const isImage = q_text.includes("<img");
    let textToDisplay = lang ? q_text : q_text_hindi; // Toggle between English and Hindi

    if (currentQuestion === index) {
      return (
        <label
          className="unanswered_lbl current_lbl"
          onClick={() => {
            updateTime();
            handleQuestionJump(index);
            setVideoShow(false);
          }}
        >
          <div className="questionBox">
            {`${index + 1}.`}
            <div className={`QBox${isImage ? " twoLines" : ""}`}>
              {isTable ? (
                <div
                  className="tableContainer"
                  dangerouslySetInnerHTML={{ __html: textToDisplay }}
                />
              ) : (
                <p dangerouslySetInnerHTML={{ __html: textToDisplay }} />
              )}
            </div>
          </div>
          <img className="arrow" src={images.right_Ar} alt="" />
        </label>
      );
    } else {
      let labelClass = "";

      if (question.check_answer === 1) {
        labelClass = "correct_answered_lbl";
      } else if (question.check_answer === 0) {
        labelClass = "incorrect_answered_lbl";
      } else {
        labelClass = "";
      }

      return (
        <label
          className={`unanswered_lbl ${labelClass}`}
          onClick={() => {
            updateTime();
            handleQuestionJump(index);
            setVideoShow(false);
          }}
        >
          <div className="questionBox">
            {`${index + 1}.`}
            <div className={`QBox${isImage ? " twoLines" : ""}`}>
              {isTable ? (
                <div
                  className="tableContainer"
                  dangerouslySetInnerHTML={{ __html: textToDisplay }}
                />
              ) : (
                <p dangerouslySetInnerHTML={{ __html: textToDisplay }} />
              )}
            </div>
          </div>
          <img className="arrow" src={images.right_Ar} alt="" />
        </label>
      );
    }
  };

  return (
    <Fragment>
      <div className="ques_paper_test_questions_solution_wrapper">
        <div className="test_questions_view_inner_wrapper">
          <div className="sec_2_wrapper">
            <div className="sec_2_inner_wrapper">
              <div className="section_content_wrapper">
                {toggleMenu ? (
                  <div
                    className="section_content_wrapper_backdrop"
                    onClick={() => {
                      setToggleMenu(false);
                    }}
                  ></div>
                ) : (
                  ""
                )}
                <div
                  className={`left_side_content_wrapper ${
                    toggleMenu ? "left_side_content_wrapperToggle" : ""
                  }`}
                >
                  <div className="sub_section_1_wrapper">
                    <button
                      type="button"
                      class="btn-close closebtn"
                      aria-label="Close"
                      onClick={() => {
                        setToggleMenu(false);
                      }}
                    ></button>
                    <div className="text_content_wrapper">
                      <p className="text_content">All Questions</p>
                    </div>
                    <div className="sub_section_2_wrapper">
                      <div className="d-flex justify-content-between align-items-center">
                        <p className="m-0">All Questions</p>
                        <div className="d-flex gap-2">
                          <button onClick={() => setisList(true)}>
                            {isList ? (
                              <img
                                src={images.user_activity_list1}
                                alt="list1"
                              />
                            ) : (
                              <img src={images.user_activity_list} alt="list" />
                            )}
                          </button>
                          <button onClick={() => setisList(false)}>
                            {isList ? (
                              <img src={images.user_activity_grid} alt="grid" />
                            ) : (
                              <img
                                src={images.user_activity_grid1}
                                alt="grid1"
                              />
                            )}
                          </button>
                        </div>
                      </div>
                      <div className="question_marking_wrapper">
                        <div className="single_marking">
                          <label className="box_pic box_pick_background_1"></label>
                          <label className="question_marking_text">
                            Correct
                          </label>
                        </div>
                        <div className="single_marking">
                          <label className="box_pic box_pick_background_4"></label>
                          <label className="question_marking_text">
                            Incorrect
                          </label>
                        </div>
                        <div className="single_marking">
                          <label className="box_pic box_pick_background_3"></label>
                          <label className="question_marking_text">
                            Unattempted
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="questions_serial_number_collection">
                      {testQuestionsDetails.length
                        ? testQuestionsDetails.map((element, index) => {
                            return (
                              <div
                                key={index}
                                className={`single_question_number_wrapper ${
                                  isList
                                    ? "single_question_number_wrapper1"
                                    : ""
                                }`}
                              >
                                {isList ? (
                                  // Render Question Label with Language Toggle
                                  <>{renderQuestionLabel(element, index)}</>
                                ) : (
                                  // Render Question Label 1 with Language Toggle
                                  <>{renderQuestionLabel1(element, index)}</>
                                )}
                              </div>
                            );
                          })
                        : null}
                    </div>
                  </div>
                </div>
                <div className="right_side_content_wrapper">
                  <div className="right_btn_wrapper">
                    <button
                      className="togglebtn"
                      onClick={() => {
                        setToggleMenu(true);
                      }}
                    >
                      Question
                    </button>
                  </div>
                  <div className="sub_section_wrapper">
                    <div className="sub_sub_section_wrapper_1">
                      <div className="sub_sub_section_wrapper_1_card">
                        <p>
                          {currentQuestion + 1}/{testQuestionsDetails.length}
                        </p>
                        <span className="countdown_timer_container">
                          {stopwatchText}
                        </span>
                        <div className="markReview">
                          <div className="bookmark_image_wrapper">
                            {testQuestionsDetails[currentQuestion] &&
                            testQuestionsDetails[currentQuestion]
                              .isAnswerGuessed ? (
                              <img
                                src={images.BookmarkBtn1}
                                className="BookmarkBtn"
                                alt=""
                                onClick={() => handleReview()}
                              />
                            ) : (
                              <img
                                alt=""
                                src={images.BookmarkBtn}
                                className="BookmarkBtn"
                                onClick={() => handleReview()}
                              />
                            )}
                          </div>
                          {currentSolutionDetails.q_text_hindi ? (
                            <button
                              className={"m2_language"}
                              onClick={() => setLang(!lang)}
                            >
                              {lang ? "अ" : "A"}
                            </button>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        height: "79vh",
                        overflowY: "scroll",
                        padding: "10px",
                      }}
                    >
                      <div className="topicWrapper">
                        Topic:{" "}
                        <span>
                          {testQuestionsDetails[currentQuestion]?.topic_name}
                        </span>
                      </div>

                      <div className="subTopicWrapper">
                        Sub Topic:{" "}
                        <span>
                          {
                            testQuestionsDetails[currentQuestion]
                              ?.sub_topic_name
                          }
                        </span>
                      </div>

                      <div className="typeLanguageWrapper">
                        Question type:{" "}
                        <span>
                          {testQuestionsDetails[currentQuestion]?.answer_type}
                        </span>
                        <div className="language_chage_icon"></div>
                      </div>

                      <div className="sub_sub_section_wrapper_2">
                        <div className="text_content_wrapper">
                          <div
                            className="questionBox"
                            style={{ display: "block" }}
                          >
                            <div
                              className="text_content"
                              style={{ display: "block" }}
                            >
                              {`Question ${Number(currentQuestion) + 1}.`}
                            </div>
                            {testQuestionsDetails.length
                              ? testQuestionsDetails.map((elem, index) => {
                                  if (index === currentQuestion) {
                                    return <>{renderSingleQuestion(elem)}</>;
                                  }
                                  return null;
                                })
                              : null}
                          </div>
                        </div>
                      </div>

                      <div className="sub_sub_section_wrapper_3">
                        {currentSolutionDetails !== null &&
                        currentSolutionDetails.option !== undefined
                          ? renderOptions(currentSolutionDetails)
                          : ""}
                      </div>

                      {currentSolutionDetails !== null &&
                      currentSolutionDetails !== undefined &&
                      currentSolutionDetails !== "" ? (
                        <div className="QuestionSolution">
                          <h5>Solution:</h5>

                          <div>
                            <p
                              dangerouslySetInnerHTML={renderSolution(
                                currentSolutionDetails
                              )}
                            ></p>

                            {currentSolutionDetails?.video_id_english
                              ? renderVideo(currentSolutionDetails)
                              : ""}
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="sub_sub_section_wrapper_5">
                    <div className="left_side_wrapper">
                      {currentQuestion > 0 && (
                        <div className="btn_wrapper">
                          <button
                            onClick={() => {
                              handlePreviousQuestionBtn(currentQuestion - 1);
                              setVideoShow(false);
                            }}
                          >
                            Previous
                          </button>
                        </div>
                      )}
                    </div>
                    <div className="right_side_wrapper">
                      {testQuestionsDetails.length === currentQuestion + 1 ? (
                        <div className="btn_wrapper">
                          <Link
                            className="backBtn"
                            to={{
                              pathname: "/ots_test_result",
                            }}
                          >
                            Exit
                          </Link>
                        </div>
                      ) : (
                        <div className="btn_wrapper">
                          <button
                            onClick={() => {
                              handleNextQuestionBtn(currentQuestion + 1);
                              setVideoShow(false);
                            }}
                          >
                            Next
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Question_paper_solution;
