import React, { Fragment, useState, useEffect, useRef } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import Lottie from "lottie-react";

import "./header_right.scss";
import images from "../../../../../../utilities/images/images";
import { isEmpty } from "../../../../../../custom_utilities/custom_useful_functions";
import { url_37 } from "../../../../../../../src/custom_utilities/api_services";
import HeaderTopright from "../header_left/header_left";
// import GitBoxAnimation from "../../../../user_refer_earn/GiftBoxAnimation.json";

import uiActions from "../../../../../../redux/ui/action";
import authActions from "../../../../../../redux/auth/action";
import userPreferenceActions from "../../../../../../redux/user_preference/action";
import userActivityActions from "../../../../../../redux/user_activity/action";
import userTestsExercisesAction from "../../../../../../redux/user_tests_exercises/action";
import userTwilioDetailsAction from "../../../../../../redux/twilio_chat/action";
import userBookmarkAction from "../../../../../../redux/user_bookmark/action";

const HeaderRight = (props) => {
  const {
    userData,
    userPreference,
    logoutUser,
    removeAnimation,
    removePreferenceData,
    removeActivityData,
    removeTestsExercisesData,
    removeTwilioDetails,
    removeBookmarkData,
  } = props;

  const history = useHistory();
  const location = useLocation();
  const [openSearch, setOpenSearch] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);

  const dropdownRef = useRef(null);

  const [state, setState] = useState({
    notificationsList: [],
  });

  const { batchDetails } = userPreference;

  useEffect(() => {
    getNotificationsList();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
        setIsNotificationOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleUserLogout = () => {
    logoutUser();
    removeAnimation();
    removePreferenceData();
    removeActivityData();
    removeTestsExercisesData();
    removeTwilioDetails();
    removeBookmarkData();
    history.push("/");
  };

  const addclass = () => {
    setOpenSearch(true);
  };

  const getNotificationsList = async () => {
    try {
      const response = await axios.get(url_37);
      if (response.data.status === 200) {
        setState((prevState) => ({
          ...prevState,
          notificationsList: response.data.data,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleClick = () => {
    history.push("/refer-and-earn");
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const toggleNotification = () => {
    setIsNotificationOpen(!isNotificationOpen);
  };

  return (
    <Fragment>
      <div className="headerRight_wrapper">
        <div className="headerRight_inner_wrapper">
          {location?.pathname !== "/refer-and-earn" && (
            <div className="referEarn_wrapper" onClick={handleClick}>
              <div className="animation-container">
                <Lottie
                  // animationData={GitBoxAnimation}
                  loop={true}
                  renderer="svg"
                  rendererSettings={{
                    preserveAspectRatio: "xMidYMid meet",
                  }}
                />
              </div>
              <div className="text">Refer & Earn</div>
            </div>
          )}

          <div className="notifications_wrapper">
            <div className="notifications_inner_wrapper">
              <div className="dropdown notication_dropDown" ref={dropdownRef}>
                {/* <button
                  
                  // className="btn dropdown-toggle cstm_drpdown_btn"
                  // type="button"
                  // onClick={toggleNotification}
                  // id="dropdownMenuButton1"
                  // data-bs-toggle="dropdown"
                  // aria-expanded="false"
                > */}
                <button
                  className="btn dropdown-toggle cstm_drpdown_btn"
                  type="button"
                  onClick={toggleNotification}
                >
                  <div className="image_wrapper">
                    {/* <Link to="/notifications"> */}
                    <img src={images.notification} alt="img" />
                    {/* </Link> */}
                  </div>
                </button>

                {isNotificationOpen && (
                  <div
                    className={`dropdown-menu custom_dropdown_notification ${
                      isNotificationOpen ? "show" : ""
                    }`}
                    ref={dropdownRef}
                  >
                    <div className="notification_dataBox">
                      <div className="heading">
                        <h3>Notification</h3>
                      </div>
                      <div className="notification_list_wrpr">
                        {state.notificationsList.length ? (
                          state.notificationsList.map((element, index) => (
                            <div key={index} className="notification_list">
                              <div className="ntf_title">
                                <h6>{element.notification_title}</h6>
                                <span>{element.time}</span>
                              </div>
                              <div className="ntf_desc">
                                <p>{element.notification_description}</p>
                              </div>
                            </div>
                          ))
                        ) : (
                          <div className="notDataFound">
                            <img
                              src={images.M2Notification}
                              alt="notification"
                            />
                            <h6>No Notification</h6>
                            <p>
                              It seems like you don't have any notification
                              right now!
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="userName_and_image_wrapper">
            <div className="userName_and_image_inner_wrapper">
              <div className="content_wrapper">
                <div className="dropdown image_container" ref={dropdownRef}>
                  <button
                    className="btn dropdown-toggle custom_dropdown_toggle"
                    type="button"
                    onClick={toggleDropdown}
                  >
                    <span className="image_wrapper">
                      <span className="image_inner_wrapper">
                        {!isEmpty(userData) ? (
                          userData.image.length ? (
                            <img src={userData.image} alt="User" />
                          ) : (
                            <img
                              src={images.dummy_user_img}
                              alt="Default user"
                            />
                          )
                        ) : (
                          <img src={images.dummy_user_img} alt="Default user" />
                        )}
                      </span>
                    </span>
                    <span className="userdetail_wrapper">
                      <span className="userdetail_1">
                        {!isEmpty(userData) && userData.name}
                      </span>
                      <span className="userdetail_2">
                        {!isEmpty(batchDetails) && batchDetails.batch}
                      </span>
                    </span>
                  </button>
                  {isDropdownOpen && (
                    <div
                      className={`dropdown-menu custom_dropdown_menu ${
                        isDropdownOpen ? "show" : ""
                      }`}
                      ref={dropdownRef}
                    >
                      <div className="profile_option_wrapper">
                        <div className="profile_option_inner_wrapper">
                          <div className="content_wrapper_1">
                            <div className="userProfile">
                              <div className="image_container">
                                {!isEmpty(userData) ? (
                                  userData.image.length ? (
                                    <img src={userData.image} alt="User" />
                                  ) : (
                                    <img
                                      src={images.dummy_user_img}
                                      alt="Default user"
                                    />
                                  )
                                ) : (
                                  <img
                                    src={images.dummy_user_img}
                                    alt="Default user"
                                  />
                                )}
                              </div>
                              <div className="text_content_wrapper_1">
                                <p className="text_content_1">
                                  {!isEmpty(userData) ? userData.name : "User"}
                                </p>
                                <p className="text_content_2">
                                  {!isEmpty(userData)
                                    ? userData.mobile
                                    : "9876543210"}
                                </p>
                              </div>
                            </div>
                            {userData.is_subscribe === 1 && (
                              <p className="text_content_3">
                                <img
                                  src={images.user_activity_12}
                                  alt="Subscribed"
                                />
                              </p>
                            )}
                          </div>
                          <div className="content_wrapper_2">
                            <div className="text_content_wrapper_1">
                              <div className="profile_link1">
                                <HeaderTopright
                                  userPreference={userPreference}
                                  userData={userData}
                                />
                              </div>
                              <Link
                                to="/user_edit_profile"
                                className="profile_link"
                              >
                                <p className="text_content_1">Profile</p>
                                <img src={images.right_Ar} alt="Arrow" />
                              </Link>
                              <Link to="/wallet" className="profile_link">
                                <p className="text_content_1">My Wallet</p>
                                <img src={images.right_Ar} alt="Arrow" />
                              </Link>
                              <Link
                                to={{
                                  pathname: "/user_edit_profile",
                                  state: { tab: "packages" },
                                }}
                                className="profile_link"
                              >
                                <p className="text_content_1">My Packages</p>
                                <img src={images.right_Ar} alt="Arrow" />
                              </Link>
                              <div className="text_content_wrapper_2 profile_link">
                                <p
                                  className="text_content_1"
                                  onClick={handleUserLogout}
                                >
                                  <img src={images.header_2} alt="Logout" />{" "}
                                  Logout
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapDispatchToProps = (dispatch) => ({
  logoutUser: () => dispatch(authActions.logoutUser()),
  removeAnimation: () => dispatch(uiActions.removeAnimation()),
  removeActivityData: () => dispatch(userActivityActions.removeActivityData()),
  removePreferenceData: () =>
    dispatch(userPreferenceActions.removePreferenceData()),
  removeTestsExercisesData: () =>
    dispatch(userTestsExercisesAction.removeTestsExercisesData()),
  removeTwilioDetails: () =>
    dispatch(userTwilioDetailsAction.removeTwilioDetails()),
  removeBookmarkData: () => dispatch(userBookmarkAction.removeBookmarkData()),
});

export default connect(null, mapDispatchToProps)(HeaderRight);
