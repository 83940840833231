import React from "react";
import "./QuestionStatusLabel.scss";
import images from "./../../../../../../../../../../utilities/images/images";

const QuestionStatusLabel = ({
  question,
  index,
  currentQuestion,
  handleQuestionJump,
  isList,
}) => {
  const renderQuestionLabel = () => {
    if (currentQuestion === index) {
      return (
        <label
          className="unanswered_lbl current_lbl"
          onClick={() => handleQuestionJump(index)}
        >
          <div className="questionBox">
            {`${index + 1}.`}
            <p dangerouslySetInnerHTML={{ __html: question.q_text }}></p>
          </div>
          <img className="arrow" src={images.right_Ar} alt="img" />
        </label>
      );
    } else {
      if (question.isAttempted) {
        if (question.isReview) {
          return (
            <label
              className="unanswered_lbl review_lbl"
              onClick={() => handleQuestionJump(index)}
            >
              <div className="questionBox">
                <div className="questionBox">
                  {`${index + 1}.`}
                  <p dangerouslySetInnerHTML={{ __html: question.q_text }}></p>
                </div>
                <img className="arrow" src={images.right_Ar} alt="img" />
              </div>
            </label>
          );
        } else {
          return (
            <label
              className="unanswered_lbl answered_lbl"
              onClick={() => handleQuestionJump(index)}
            >
              <div className="questionBox">
                {`${index + 1}.`}
                <p dangerouslySetInnerHTML={{ __html: question.q_text }}></p>
              </div>
              <img className="arrow" src={images.right_Ar} alt="img" />
            </label>
          );
        }
      } else {
        if (question.isReview) {
          return (
            <label
              className="unanswered_lbl review_lbl"
              onClick={() => handleQuestionJump(index)}
            >
              <div className="questionBox">
                {`${index + 1}.`}
                <p dangerouslySetInnerHTML={{ __html: question.q_text }}></p>
              </div>
              <img className="arrow" src={images.right_Ar} alt="img" />
            </label>
          );
        } else if (question.isSkipped) {
          return (
            <label
              className="unanswered_lbl skip_lbl"
              onClick={() => handleQuestionJump(index)}
            >
              <div className="questionBox">
                {`${index + 1}.`}
                <p dangerouslySetInnerHTML={{ __html: question.q_text }}></p>
              </div>
              <img className="arrow" src={images.right_Ar} alt="img" />
            </label>
          );
        } else if (
          question.option?.filter((m) => m.is_user_correct_ans === 1).length ||
          question.integer_answer?.length
        ) {
          return (
            <label
              className="unanswered_lbl answered_lbl"
              onClick={() => handleQuestionJump(index)}
            >
              <div className="questionBox">
                {`${index + 1}.`}
                <p dangerouslySetInnerHTML={{ __html: question.q_text }}></p>
              </div>
              <img className="arrow" src={images.right_Ar} alt="img" />
            </label>
          );
        } else {
          return (
            <label
              className="unanswered_lbl"
              onClick={() => handleQuestionJump(index)}
            >
              <div className="questionBox">
                {`${index + 1}.`}
                <p dangerouslySetInnerHTML={{ __html: question.q_text }}></p>
              </div>
              <img className="arrow" src={images.right_Ar} alt="img" />
            </label>
          );
        }
      }
    }
  };

  const renderQuestionLabel1 = () => {
    if (currentQuestion === index) {
      return (
        <label
          className="unanswered_lbl current_lbl"
          onClick={() => handleQuestionJump(index)}
        >
          <div className="questionBox">{`${index + 1}`}</div>
        </label>
      );
    } else {
      if (question.isAttempted) {
        if (question.isReview) {
          return (
            <label
              className="unanswered_lbl review_lbl"
              onClick={() => handleQuestionJump(index)}
            >
              <div className="questionBox">
                <div className="questionBox">{`${index + 1}`}</div>
              </div>
            </label>
          );
        } else {
          return (
            <label
              className="unanswered_lbl answered_lbl"
              onClick={() => handleQuestionJump(index)}
            >
              <div className="questionBox">{`${index + 1}`}</div>
            </label>
          );
        }
      } else {
        if (question.isReview) {
          return (
            <label
              className="unanswered_lbl review_lbl"
              onClick={() => handleQuestionJump(index)}
            >
              <div className="questionBox">{`${index + 1}`}</div>
            </label>
          );
        } else if (question.isSkipped) {
          return (
            <label
              className="unanswered_lbl skip_lbl"
              onClick={() => handleQuestionJump(index)}
            >
              <div className="questionBox">{`${index + 1}`}</div>
            </label>
          );
        } else if (
          question.option?.filter((m) => m.is_user_correct_ans === 1).length ||
          question.integer_answer?.length
        ) {
          return (
            <label
              className="unanswered_lbl answered_lbl"
              onClick={() => handleQuestionJump(index)}
            >
              <div className="questionBox">{`${index + 1}`}</div>
            </label>
          );
        } else {
          return (
            <label
              className="unanswered_lbl"
              onClick={() => handleQuestionJump(index)}
            >
              <div className="questionBox">{`${index + 1}`}</div>
            </label>
          );
        }
      }
    }
  };

  return (
    <>
      {isList ? (
        <div className="single_question_number_wrapper single_question_number_wrapper1">
          {renderQuestionLabel()}
        </div>
      ) : (
        <div className="single_question_number_wrapper_box">
          {renderQuestionLabel1()}
        </div>
      )}
    </>
  );
};

export default QuestionStatusLabel;
