import React from "react";
import "./CustomPaperBox.scss";

const CustomPaperBox = ({ qBank, handleViewSolution, handleTakeTest }) => {
  return (
    <div className="questionBankCustomPaperBox" key={qBank.question_bank_id}>
      <div className="questionBankCustomPaperName">
        <div className="paperName">{qBank.subject}</div>
      </div>
      <div className="questions_data_count">
        <ul>
          <li className="qb_total">
            <span>{qBank.unattempt + qBank.incorrect + qBank.correct}</span>{" "}
            Ques
          </li>{" "}
          <li className="qb_correct">
            <span> {qBank.correct} </span>Correct
          </li>{" "}
          <li className="qb_wrong">
            <span> {qBank.incorrect} </span>Incorrect
          </li>{" "}
          <li className="qb_unattempt">
            <span>{qBank.unattempt}</span> Unattempt
          </li>{" "}
        </ul>
      </div>
      <div className="no_of_quests_startBtn">
        <div className="created_date">
          Attempted on: <strong>{qBank.created_on}</strong>
        </div>
        <div className="startBtn">
          <div
            onClick={
              qBank.is_submit === 0
                ? () => handleViewSolution(qBank.acps_id)
                : () => handleTakeTest(qBank.subject)
            }
            className="view_sol_btn"
          >
            {qBank.is_submit === 0 ? "View Solution" : "Take Test"}
          </div>{" "}
          <span>{">"}</span>
        </div>
      </div>
    </div>
  );
};

export default CustomPaperBox;
