import React, { Fragment, useEffect, useState } from "react";
import "./user_update_course_modal.scss";
import { isEmpty } from "../../../../custom_utilities/custom_useful_functions";
import PreferenceSelectionModal from "../preference_selection_modal/preference_selection_modal";

const UpdateCourseModal = (props) => {
  let {
    handleCloseModal,
    state,
    userPreference,
    handleChangeTarget,
    handleChangeClass,
    handleChangeCourse,
    handleChangeBatch,
    handleUpdateCourse,
    userData,
    isUpdateCourseModalOpen,
    isPreferenceModalOpen,
    setUpdateCourseModalOpen,
    setPreferenceModalOpen,
  } = props;

  const [isCourseChanged, setIsCourseChanged] = useState(false); // Track if course has been changed

  // Detect if course has been changed
  const handleCourseChange = (event) => {
    handleChangeCourse(event);
    setIsCourseChanged(true); // Mark as changed when course is selected
  };

  const handleClassChange = (event) => {
    handleChangeClass(event);
    setIsCourseChanged(true); // Mark as changed when class is selected
  };

  const handleTargetChange = (event) => {
    handleChangeTarget(event);
    setIsCourseChanged(true); // Mark as changed when target is selected
  };

  useEffect(() => {
    const modalElement = document.getElementById("user_selection_modal");

    if (modalElement) {
      const handleBackdropClick = (event) => {
        if (event.target === event.currentTarget) {
          handleCloseModal();
        }
      };

      modalElement.addEventListener("click", handleBackdropClick);

      return () => {
        modalElement.removeEventListener("click", handleBackdropClick);
      };
    }
  }, [handleCloseModal]);

  const handleOpenPreferenceSelectionModal = () => {
    // Check if anything has changed; if not, open the PreferenceSelectionModal
    if (!isCourseChanged) {
      setUpdateCourseModalOpen(false);
      setPreferenceModalOpen(true);
    } else {
      handleUpdateCourse(); // This will make the API call to update the course
    }
  };

  return (
    <Fragment>
      {isUpdateCourseModalOpen && (
        <div
          className="modal fade update_course_modal"
          id="user_selection_modal"
          tabIndex="-1"
          aria-labelledby="user_selection_modal_label"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5
                  className="modal-title"
                  id="course_and_subject_user_modal_label"
                >
                  Update your Package
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={handleCloseModal}
                ></button>
              </div>
              <div className="modal-body">
                <div className="modal_body_wrapper">
                  <section className="sec_1_wrapper">
                    <div className="text_content_wrapper">
                      <p className="text_content">
                        Want to change your course?
                      </p>
                    </div>
                    <div className="target_collection_wrapper">
                      <div className="single_target_wrapper">
                        <input
                          type="radio"
                          id={`target_${state?.updateCourse?.current_user_course}`}
                          name="target"
                          value={state?.updateCourse?.current_user_course}
                          onChange={(event) => handleTargetChange(event)}
                        />
                        <label
                          htmlFor={`target_${state?.updateCourse?.current_user_course}`}
                        >
                          {state?.updateCourse?.current_user_course}
                        </label>
                      </div>
                    </div>
                  </section>
                  {!isEmpty(state) &&
                  state?.updateCourse?.last_viewed_course?.length ? (
                    <section className="sec_2_wrapper">
                      <div className="text_content_wrapper">
                        <p className="text_content">Last Viewed</p>
                      </div>
                      <div className="class_collection_wrapper">
                        {state?.updateCourse?.last_viewed_course.map(
                          (element, index) => {
                            return (
                              <div key={index} className="single_class_wrapper">
                                <input
                                  type="radio"
                                  id={`class_${element?.course_id}`}
                                  name="class"
                                  value={element?.course_id}
                                  onChange={(event) => handleClassChange(event)}
                                  checked={
                                    element?.course_id ===
                                    userPreference?.course_id
                                  }
                                />
                                <label htmlFor={`class_${element?.course_id}`}>
                                  {element?.course}
                                </label>
                              </div>
                            );
                          }
                        )}
                      </div>
                    </section>
                  ) : null}

                  {!isEmpty(state) &&
                  state?.updateCourse?.recommended_course?.length ? (
                    <section className="sec_3_wrapper">
                      <div className="text_content_wrapper">
                        <p className="text_content">Recommended for you</p>
                      </div>
                      <div className="course_collection_wrapper">
                        {state.updateCourse?.recommended_course.map(
                          (element, index) => {
                            return (
                              <div
                                key={index}
                                className="single_course_wrapper"
                              >
                                <input
                                  type="radio"
                                  id={`course_${element?.course_id}`}
                                  name="course"
                                  value={element?.course_id}
                                  checked={
                                    element?.course_id ===
                                    userPreference?.course_id
                                  }
                                  onChange={(event) =>
                                    handleCourseChange(event)
                                  }
                                />
                                <label htmlFor={`course_${element?.course_id}`}>
                                  {element?.course}
                                </label>
                              </div>
                            );
                          }
                        )}
                      </div>
                    </section>
                  ) : null}
                </div>
              </div>
              <div className="modal-footer">
                <div className="modal_footer">
                  <button
                    type="button"
                    onClick={handleOpenPreferenceSelectionModal}
                  >
                    {isCourseChanged ? "Submit" : "Check out other courses"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {isPreferenceModalOpen && (
        <div
          className={`modal fade ${isPreferenceModalOpen ? "show" : ""}`}
          id="preference_selection_modal"
          tabIndex="-1"
          aria-labelledby="preference_selection_modal_label"
          aria-hidden={!isPreferenceModalOpen}
          style={{ display: isPreferenceModalOpen ? "block" : "none" }}
        >
          <PreferenceSelectionModal
            state={state}
            userPreference={state.userPreference}
            targets={state.targets}
            classes={state.classes}
            courses={state.courses}
            batches={state.batches}
            handleChangeTarget={handleChangeTarget}
            handleChangeClass={handleChangeClass}
            handleChangeCourse={handleChangeCourse}
            handleChangeBatch={handleChangeBatch}
            handleUpdateCourse={handleUpdateCourse}
            handleCloseModal={handleCloseModal}
            userData={userData}
            isPreferenceModalOpen={isPreferenceModalOpen}
            setPreferenceModalOpen={setPreferenceModalOpen}
          />
        </div>
      )}
    </Fragment>
  );
};

export default UpdateCourseModal;
