import React from "react";
import images from "../../../../../../utilities/images/images";
import "./TenHoursFreeVideosBanner.scss";

const TenHoursFreeVideosBanner = ({ history }) => {
  const handleOnClickWatchNow = () => {
    history.push("/subject_component");
  };
  return (
    <div className="ten_hours_free_container">
      <div className="firstt">
        <div className="ten_hourse_videoimg">
          <img src={images.watchFreeVideoIcon} alt="video-icon" />
        </div>

        <div className="ten_hours_text">
          Access up to <span className="tenhrs">2 days</span> of free
          lectures. <span className="free-text">Free</span>
        </div>
      </div>

      <div className="secondd">
        <div className="ten_hours_btn" onClick={handleOnClickWatchNow}>
          <button>
            Watch Now    <img src={images.watchFreeVideoArrow} alt="right-arrow" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default TenHoursFreeVideosBanner;
